import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

function Thanks() {
	return (
		<Layout>
			<SEO title="Thanks" />
			<div className="pattern-bg">
				<div class="thank-you">
				<div className="py-10 mx-auto max-w-screen-xl px-4 sm:px-6 md:py-16 lg:py-20">
					<div className="text-center">
						<h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
							Thank you!
							<br />
							<span className="text-gray-700">We will be in contact soon!</span>
						</h2>
					</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Thanks;
